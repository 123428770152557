import React, { useState ,useEffect} from "react";
import { useContext } from 'react';
import "./App.css";
import Routes from "./Routes";
import Footer from "./containers/Footer";
import Header from "./containers/Header";
import { AppContext } from "./lib/contextLib";
import { Auth } from "aws-amplify";
import {
  DatabaseOutlined,
  HomeOutlined,
  UserOutlined,
  CloudServerOutlined,
  CustomerServiceOutlined,
} from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import { Link} from "react-router-dom";
import { onError } from "./lib/errorLib";
import { CartProvider } from './context/cart'
import { SideMenuContext } from './context/sidemenu'
import { OnDemandMoleculesProvider } from './context/onDemandMolecules'
import 'react-toastify/dist/ReactToastify.css';
const { Content, Sider } = Layout;

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [collapsed,] = useState(false);
  const { selectedMenuItem,} = useContext(SideMenuContext);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    } catch (e) {
      if (e !== "No current user") {
        onError(e);
      }
    }

    setIsAuthenticating(false);
  }

  return (
    !isAuthenticating && (
      <div className="App">
        <OnDemandMoleculesProvider>
        <CartProvider>
        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
          <Header />
          <Layout>
            {isAuthenticated ? (
                  <Sider trigger={null} collapsible collapsed={collapsed} style={{ background: colorBgContainer }} className="dashboard-sider">
                    <div className="demo-logo-vertical" />
                    <Menu
                      theme="light"
                      mode="inline"
                      defaultSelectedKeys={['dashboard']}
                      selectedKeys={[selectedMenuItem]}
                    >
                      <Menu.Item key="dashboard" icon={<HomeOutlined />}>
                      <Link to="/dashboard">Dashboard</Link>
                      </Menu.Item>
                      <Menu.Item key="profile" icon={<UserOutlined />}>
                        <Link to="/profile">Profile</Link>
                      </Menu.Item>
                      <Menu.Item key="search" icon={<DatabaseOutlined />}>
                        <Link to="/queryParent">Search</Link>
                      </Menu.Item>
                      <Menu.Item key="calculationSetUp" icon={<CloudServerOutlined />}>
                        <Link to="/calculationSetUp">Calculate</Link>
                      </Menu.Item>
                      <Menu.Item key="support" icon={<CustomerServiceOutlined />}>
                        <Link to="/support">Support</Link>
                      </Menu.Item>
                    </Menu>


                  </Sider>
                ) : (
                  <>
                  </>
                )}
            <Content>
              <Routes />
            </Content>
          </Layout>
        </AppContext.Provider>
        </CartProvider>
        </OnDemandMoleculesProvider>
        <Footer />
      </div>
    )
  );
}
export default App;
