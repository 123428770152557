import { useContext, useState } from 'react';
import { CartContext } from '../context/cart';
import { Table } from "ant-table-extensions";
import { Button, Card, Form, Input, Select } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import { API, Auth } from "aws-amplify";
import "./Cart.css";

export default function Cart() {
  const { cartItems, clearCart, removeFromCart } = useContext(CartContext);
  const [selectedQuantities, setSelectedQuantities] = useState({});
  const [customQuantities, setCustomQuantities] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleQuantityChange = (id, selectedQuantity) => {
    setSelectedQuantities(prevState => ({
      ...prevState,
      [id]: selectedQuantity
    }));
  };

  const handleCustomQuantityChange = (id, customQuantity) => {
    setCustomQuantities(prevState => ({
      ...prevState,
      [id]: customQuantity
    }));
  };

  const getSelectedPrice = (item) => {
    const selectedQuantity = selectedQuantities[item.id] || item.priceList[0]?.quantity;
    const selectedPriceObj = item.priceList.find(priceObj => priceObj.quantity === selectedQuantity);
    return selectedPriceObj ? `${selectedPriceObj.price} ${selectedPriceObj.unit}` : "Please enter desired quantity";
  };

  const handleRemove = (item) => {
    removeFromCart(item);
     setSelectedQuantities((prevSelectedQuantities) => {
      const updatedQuantities = { ...prevSelectedQuantities };
      delete updatedQuantities[item.id];
      return updatedQuantities;
    });

    setCustomQuantities((prevCustomQuantities) => {
      const updatedQuantities = { ...prevCustomQuantities };
      delete updatedQuantities[item.id];
      return updatedQuantities;
    });
  };


  const onFinish = async (values) => {
    try {
      setIsLoading(true)
      const { firstName, lastName, address, region, number } = values;
      const updatedCartItems = cartItems.map((item) => {
        const selectedQuantity = selectedQuantities[item.id] || item.priceList[0]?.quantity;
        const customQuantity = customQuantities[item.id];
        return {
          ...item,
          selectedQuantity: customQuantity || selectedQuantity,
          indicativePrice: customQuantity ? "Price upon request" : getSelectedPrice(item),
        };
    });
      const contentDict = {
        firstName,
        lastName,
        ...(address && { address }),
        ...(region && { region }),
        ...(number && { number }),
        cartItems: updatedCartItems
      };
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;

      const request = {
        headers: {
          Authorization: "Bearer " + token
        },
        body: {
          contentDict
        }
      };

      const jsonResponse = await API.put("diadem", "/sendEmail", request);
      if (jsonResponse.statusCode===200){
        setIsLoading(false);
        toast.success(jsonResponse["body"]["message"]);
      }
      else{
        const body=JSON.parse(jsonResponse.body)
        if (body && body.exception){
          toast.error(body.exception);
          setIsLoading(false);
        }
      }

    } catch (error) {
      toast.error('Error requesting quote:', error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const columns = [
    {
      title: 'InChIKey',
      dataIndex: 'inchiKey',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'Indicative Price',
      dataIndex: 'price',
    },
    {
      title: 'Action',
      dataIndex: 'action',
    }
  ];

  const data = [];
  cartItems.forEach((item, i) => {
    data.push({
      key: i,
      inchiKey: item.inchiKey,
      quantity: (
        item.priceList.length > 0 ? (
          <div>
            <Select
              defaultValue={item.priceList[0].quantity}  // Default to the first quantity
              onChange={(value) => handleQuantityChange(item.id, value)}
              style={{ width: 120 }}
              value={selectedQuantities[item.id] || item.priceList[0].quantity}
            >
              {item.priceList.map(priceObj => (
                <Select.Option key={priceObj.quantity} value={priceObj.quantity}>
                  {priceObj.quantity}
                </Select.Option>
              ))}
              <Select.Option key="Other" value="Other">Other</Select.Option>
            </Select>
            {selectedQuantities[item.id] === 'Other' && (
             <Input
               placeholder="Enter custom quantity"
               onChange={(e) => handleCustomQuantityChange(item.id, e.target.value)}
               style={{ width: 150, marginTop: 10 }}
               value={customQuantities[item.id] || ''}
             />

            )}
          </div>
        ) : (
          <div>
            <Input
              placeholder="Enter your desired quantity"
              onChange={(e) => handleCustomQuantityChange(item.id, e.target.value)}
              style={{ width: 150 }}
              value={customQuantities[item.id] || ''}
            />
          </div>
        )
      ),
      price: (
        selectedQuantities[item.id] === 'Other' || customQuantities[item.id]
          ? "Price upon request"
          : getSelectedPrice(item)
      ),
      action:(
        <Button type="primary" onClick={() => handleRemove(item)}>
          Remove
        </Button>
      ),
    });
  });

  return (
    <>
      <ToastContainer position="top-center" />
      {isLoading ? (
        <div class="loading" id="loading-wrapper">
          <div id="loading-text">LOADING</div>
          <div id="loading-content"></div>
        </div>
      ) : (
          <>
          </>
    )}
      <div>
        <h3>Cart</h3>
        {cartItems.length > 0 ? (
          <div>
            <Card className="cart-card">
              <Table columns={columns} dataSource={data} />
              <Button
                onClick={() => {
                  clearCart();
                }}
              >
                Clear cart
              </Button>
            </Card>
          </div>
        ) : (
          <h4>Your cart is empty</h4>
        )}
      </div>
      <div>
        <Card className="billingcart-card">
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[{ required: true, message: 'Please input your first name!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[{ required: true, message: 'Please input your last name!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Phone number" name="number">
              <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Request Quote
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </>
  );
}
